import { FC, memo, useCallback } from 'react';
import zxcvbn from 'zxcvbn';
import styles from './PasswordStrengthMeter.module.scss';

interface Props {
    password: string;
}

const PasswordStrengthMeter: FC<Props> = ({ password }) => {
    const testedResult = zxcvbn(password);

    const createPasswordLabel = useCallback((result) => {
        switch (result.score) {
            case 0:
                return `${result.feedback.warning ? result.feedback.warning + '.' : ''} ${result.feedback.suggestions && result.feedback.suggestions.length ? result.feedback.suggestions[0] : ''}`;
            case 1:
                return `Password strength: Weak ${result.feedback.warning ? '- ' + result.feedback.warning + '.' : ''}`;
            case 2:
                return `Password strength: Fair ${result.feedback.warning ? '- ' + result.feedback.warning + '.' : ''}`;
            case 3:
                return 'Password strength: Good';
            case 4:
                return 'Password strength: Strong';
            default:
                return 'Create a strong password with a mix of letters, numbers and symbols and minimum of 8 characters.';
        }
    }, [])

    const createPasswordClass = useCallback((result) => {
        switch (result.score) {
            case 0:
                return `Weak`;
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return 'Weak';
        }
    }, [])

    return (
        <>
            <div className={styles.passwordStrengthMeter}>
                <progress
                    className={`${styles['passwordStrengthMeterProgress']} ${styles[`strength${createPasswordClass(testedResult)}`]}`}
                    value={testedResult.score}
                    max="4"
                />
                
                <label className={`${styles['passwordStrengthMeterLabel']} ${styles[`strength${password ? createPasswordClass(testedResult) : 'Default'}`]}`}>
                    {password && (
                        <>
                            {createPasswordLabel(testedResult)}
                        </>
                    )}

                    {!password && (
                        <>
                            The password should have a minimum of 8 characters.
                        </>
                    )}
                </label>
            </div>
        </>
    )
};

export default memo(PasswordStrengthMeter);
