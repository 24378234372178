import React, { FC, memo } from 'react';

import styles from './MyAccount.module.scss';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import { Card } from './components/Card';
import { navigationCards } from 'constants/navigationConstants';
import NavLinks from 'components/NavLinks/NavLinks';

const MyAccountComponent: FC = () => {
    return (
        <div className={styles.root}>
            <PageBlock>
                <NavLinks />
                <div className={styles.cardsContainer}>
                    {navigationCards.map((item, index) => (
                        <Card
                            key={index}
                            item={item}
                        />
                    ))}
                </div>
            </PageBlock>
        </div>
    );
};

export const MyAccount = memo(MyAccountComponent);
