import { createSlice } from '@reduxjs/toolkit';
import { getBrands, getFranchises, getCharacters } from './thunks';
import { BrandsPageSlice } from './types';

const initialState: BrandsPageSlice = {
  brands: [],
  franchises: [],
  characters: [],
  selectedBrandId: null,
  selectedFranchiseId: null,
};

export const brandsPageSlice = createSlice({
  name: 'brandsPage',
  initialState,
  reducers: {
    setSelectedBrandId(state, action) {
      state.selectedBrandId = action.payload;
    },
    setSelectedFranchiseId(state, action) {
      state.selectedFranchiseId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBrands.fulfilled, (state, action) => {
      state.brands = action.payload;
    });
    builder.addCase(getFranchises.fulfilled, (state, action) => {
      state.franchises = action.payload;
    });
    builder.addCase(getCharacters.fulfilled, (state, action) => {
      state.characters = action.payload;
    });
  },
});

export const { setSelectedBrandId, setSelectedFranchiseId } = brandsPageSlice.actions;

export default brandsPageSlice.reducer;
