import { SortDirection } from '../enums';
import { SortInfo, TableFilters } from '../types';
import { PaginationInfo, TableFieldType, TableDataRow } from './types';

const getIsValueMatchingText = (field: TableFieldType, filterValue: string | number | string[]): boolean =>
  typeof field === 'string' && typeof filterValue === 'string'
    ? field.toLowerCase().includes(filterValue.toLowerCase())
    : field === filterValue;

const getTableRowCompareFunc =
  (fieldKey: string, direction: SortDirection) =>
  (a: TableDataRow, b: TableDataRow): number => {
    const [aNormalized, bNormalized] =
      typeof a[fieldKey] === 'string'
        ? [(<string>a[fieldKey]).toLowerCase(), (<string>b[fieldKey]).toLowerCase()]
        : [a[fieldKey], b[fieldKey]];

    const sortDir = a[fieldKey] instanceof Date ? -1 : 1;
    const compareResult =
      aNormalized > bNormalized || aNormalized === null || aNormalized === undefined ? sortDir : -sortDir;

    return direction === SortDirection.desc ? compareResult : -compareResult;
  };

const getColumnFilterFunc =
  (filters: TableFilters) =>
  (row: TableDataRow): boolean =>
    Object.entries(filters).every(([field, filterValue]) => {
      return Array.isArray(filterValue)
        ? row[field] &&
            (filterValue.length === 0 ||
              filterValue.some((filterValue: string) => getIsValueMatchingText(row[field], filterValue)))
        : getIsValueMatchingText(row[field], filterValue);
    });

export const applyFiltersFunc = (tableData: TableDataRow[], filters: TableFilters): TableDataRow[] =>
  tableData.filter(getColumnFilterFunc(filters));

export const applyPaginationFunc = (
  rewardsTableData: TableDataRow[],
  { pageNumber, rowsPerPage }: PaginationInfo
): TableDataRow[] => {
  return rowsPerPage > 0
    ? rewardsTableData.slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)
    : rewardsTableData;
};

export const applySortFunc = (tableData: TableDataRow[], tableSort: SortInfo): TableDataRow[] => {
  const { fieldKey, direction } = tableSort;
  if (fieldKey && direction) {
    tableData = [...tableData].sort(getTableRowCompareFunc(fieldKey, direction));
  }

  return [...tableData];
};
