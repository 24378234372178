import httpClient from 'http/httpClient';
import { Brand } from 'interfaces/brand';
import { Character } from 'interfaces/character';
import { Franchise } from 'interfaces/franchises';

export const fetchBrands = (orgId: string): Promise<Brand[]> =>
  httpClient.get(`/v1/policy/brand/${orgId}/brands`).then(({ data }) => data.data.brands);

export const fetchFranchises = ({ orgId, brandId }: { orgId: string; brandId: string }): Promise<Franchise[]> =>
  httpClient.get(`/v1/policy/brand/${orgId}/${brandId}/franchises`).then(({ data }) => data.data.franchises);

export const fetchCharacters = ({
  orgId,
  brandId,
  franchiseId,
}: {
  orgId: string;
  brandId: string;
  franchiseId: string;
}): Promise<Character[]> =>
  httpClient
    .get(`/v1/policy/brand/${orgId}/${brandId}/${franchiseId}/characters`)
    .then(({ data }) => data.data.characters);
