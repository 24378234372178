import React, { FC, memo, ReactNode } from 'react';
import styles from './PageBlock.module.scss';
interface Props {
  children: ReactNode;
}
const PageBlock: FC<Props> = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

export default memo(PageBlock);
