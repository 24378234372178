import { createSelector } from 'reselect';
import {
  TableDataSelectorArguments,
  TableDataSortedFilteredSelectorReturnType,
  TableDataSortedFilteredPaginatedSelectorReturnType,
} from './types';
import { applyPaginationFunc, applySortFunc, applyFiltersFunc } from './helpers';

export const getTableDataFilteredSortedPaginated = <T>({
  getData,
  getSort,
  getFilters,
  getPagination,
}: TableDataSelectorArguments<T>): TableDataSortedFilteredPaginatedSelectorReturnType<T> => {
  const getTableDataFiltered = createSelector([getData, getFilters], applyFiltersFunc);
  const getTableDataFilteredSorted = createSelector([getTableDataFiltered, getSort], applySortFunc);
  return createSelector([getTableDataFilteredSorted, getPagination], applyPaginationFunc);
};

export const getTableDataFilteredSorted = <T>({
  getData,
  getSort,
  getFilters,
}: TableDataSelectorArguments<T>): TableDataSortedFilteredSelectorReturnType<T> => {
  const getTableDataFiltered = createSelector([getData, getFilters], applyFiltersFunc);
  return createSelector([getTableDataFiltered, getSort], applySortFunc);
};
