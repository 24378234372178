import { createAsyncThunk } from '@reduxjs/toolkit';
import { FieldError, postInviteUser } from 'api/user';
import { RootState } from 'redux/store';
import {toast} from 'react-toastify';

export const inviteUser = createAsyncThunk<void, any, { rejectValue: string | null }>(
  'team/inviteUser',
  async (params, {getState, rejectWithValue }) => {
    const {
      organisation: { currentOrgId },
    } = getState() as RootState;
    if (!currentOrgId) {
      return;
    }

    return await postInviteUser(currentOrgId, params)
      .then(() => {
        toast(`Invite has been sent to ${params.email}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      })
      .catch(({ data, status }) =>
        rejectWithValue(status === 'fail' ? (Object.values(data)[0] as FieldError).message : null)
      );
  }
);
