import Organisation from 'interfaces/organisation';
import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const getOrganisations = (state: RootState): Organisation[] => state.organisation.organisations;
const getCurrentOrganisationId = (state: RootState): string | null => state.organisation.currentOrgId;

export const getCurrentOrganisation = createSelector(
  [getOrganisations, getCurrentOrganisationId],
  (organisations, currentOrgId) => organisations.find(({ organisation_id }) => organisation_id === currentOrgId)
);
