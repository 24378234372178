export const config = {
  services: {
    login: 'http://localhost:3001',
    api: 'https://api.testing.xelacore.io',
    reach: 'http://localhost:3000',
    account: 'http://localhost:3000',
    reg: 'http://localhost:8000',
    plm: 'http://localhost:3000',
    cam: 'http://localhost:3000',
  },
  appDomain: '',
  baseUrl: 'https://api.testing.xelacore.io',
  client_id: 'f21ce494-0385-4c2e-9175-96f15344aab7',
  redirect_uri: 'https://dev.xelacore.io',
  register_url: 'https://xelacore.zendesk.com/hc/en-gb/articles/4417717599377'
};

export default config;
