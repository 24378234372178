import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import AuthProtectedRoute from 'components/AuthProtectedRoute/AuthProtectedRoute';

import { Brands } from 'pages/Brands';
import { Company } from 'pages/Company';
import { MyAccount } from 'pages/MyAccount';
import { MyProfile } from 'pages/MyProfile';
import { Settings } from 'pages/Settings';
import { Team } from 'pages/Team';
import { Terms } from 'pages/Terms';
import { RegisterUser } from 'pages/RegisterUser';
import { Security } from 'pages/Security';

import './styles/App.scss';
import { ModuleSelection } from 'pages/ModuleSelection';

export default function Routes(): React.ReactElement {
  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <AuthProtectedRoute exact path="/">
        <MyAccount />
      </AuthProtectedRoute>
      <AuthProtectedRoute exact path="/profile">
        <MyProfile />
      </AuthProtectedRoute>
      <AuthProtectedRoute exact path="/company">
        <Company />
      </AuthProtectedRoute>
      <AuthProtectedRoute exact path="/security">
        <Security />
      </AuthProtectedRoute>
      <AuthProtectedRoute exact path="/team">
        <Team />
      </AuthProtectedRoute>
      <AuthProtectedRoute exact path="/brands">
        <Brands />
      </AuthProtectedRoute>
      <AuthProtectedRoute exact path="/settings">
        <Settings />
      </AuthProtectedRoute>
      <AuthProtectedRoute exact path="/terms">
        <Terms />
      </AuthProtectedRoute>
      <AuthProtectedRoute hideMenu={true} hideCover={true} exact path="/module-selection">
        <ModuleSelection />
      </AuthProtectedRoute>

      <Route exact path="/register/user">
        <RegisterUser />
      </Route>
    </Switch>
  );
}
