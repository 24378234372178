import authHttpClient from 'http/authHttpClient';
import httpClient from 'http/httpClient';
import {Employee} from 'interfaces/employee';
import {convertEmployeeFromResponse} from './helpers';
import {ConfigResponse, FetchOrganisationsResponse, OrganisationUpdateEntity} from './interface';
import axios from 'axios';

export const fetchOrganisations = (): Promise<{ data: FetchOrganisationsResponse }> => {
    return authHttpClient.get('/v1/auth/organisations?page=1&page_size=100000').then(({data}) => data);
};

export const patchOrganisation = (
    organisation: OrganisationUpdateEntity
): Promise<{ data: { details: OrganisationUpdateEntity } }> => {
    return httpClient.patch('/v1/auth/organisations?page=1&page_size=100000', organisation).then(({data}) => data);
};

export const fetchAliases = (): Promise<string[]> => {
    return httpClient
        .get('/v1/policy/own/aliases/list')
        .then(({data}) => data.data.aliases.map(({alias}: { alias: string }) => alias));
};

export const fetchConfig = (): Promise<ConfigResponse> => {
    return httpClient.get('/v1/policy/config').then(
        ({
             data: {
                 data: {
                     config: {gtin_waiver = false, gcp_list = []},
                 },
             },
         }) => ({
            gtin_waiver,
            gcp_list,
        })
    );
};

export const fetchEmployees = (orgId: string): Promise<Employee[]> => {
    return httpClient
        .get(`/v1/auth/organisations/${orgId}/employees`, {
            params: {
                page: 1,
                page_size: 20000,
            },
        })
        .then(
            ({
                 data: {
                     data: {employees},
                 },
             }) => employees.map(convertEmployeeFromResponse)
        );
}


export const fetchAllEmployees = (orgId: string): Promise<Employee[]> => {
    return axios.all([
        httpClient.get(`/v1/auth/organisations/${orgId}/employees`),
        httpClient.get(`/v1/auth/organisations/${orgId}/employees/pending`)
    ])
        .then(axios.spread((activeEmployeesListData, pendingEmployeesListData) => {
            return [... activeEmployeesListData.data.data.employees,...pendingEmployeesListData.data.data.pendingInvites].map(convertEmployeeFromResponse)
        }));
}
