export const getPaging = ({
  rowsPerPage,
  totalRows,
  maxVisiblePages,
  pageNumber,
}: {
  rowsPerPage: number;
  totalRows: number;
  maxVisiblePages: number;
  pageNumber: number;
}): { startPage: number; endPage: number; totalPages: number } => {
  const totalPages = rowsPerPage > 0 ? Math.ceil(totalRows / rowsPerPage) : 1;

  let startPage = 0;
  let endPage = totalPages;

  if (totalPages <= maxVisiblePages) {
    startPage = 0;
    endPage = totalPages;
  } else {
    const maxPagesBeforeCurrentPage = Math.floor(maxVisiblePages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxVisiblePages / 2) - 1;

    if (pageNumber <= maxPagesBeforeCurrentPage) {
      startPage = 0;
      endPage = maxVisiblePages;
    } else if (pageNumber + maxPagesAfterCurrentPage >= totalPages) {
      startPage = totalPages - maxVisiblePages;
      endPage = totalPages;
    } else {
      startPage = pageNumber - maxPagesBeforeCurrentPage;
      endPage = pageNumber + maxPagesAfterCurrentPage + 1;
    }
  }

  return { startPage, endPage, totalPages };
};
