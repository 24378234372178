import config from '../config/env-config';
import cookies from 'js-cookie';
import {
  USER_REFRESH_TOKEN_COOKIE,
  USER_TOKEN_COOKIE,
  ORG_ID_COOKIE,
} from '../constants/authConstants';

export const redirectToLogin = (): void => {
  const source = config.services.account.split('://')[1];
  const path = `${config.services.login}?source=${source}`;
  window.location.replace(path);
};

export const navigateToLoginOnRegister = (): void => {
  const source = config.services.reg.split('://')[1];
  window.location.href = `${config.services.login}?source=${source}`;
};

export const logout = (): void => {
  clearAuthData();
  redirectToLogin();
};

export const clearAuthData = (): void => {
  clearUserCookies();
};

export const clearUserCookies = (): void => {
  cookies.remove(USER_REFRESH_TOKEN_COOKIE,{domain:config.appDomain, secure: true, sameSite: 'strict'});
  cookies.remove(USER_TOKEN_COOKIE,{domain:config.appDomain, secure: true, sameSite: 'strict'});
};

export const clearOrganisationCookies = (): void => {
  cookies.remove(ORG_ID_COOKIE,{domain:config.appDomain, secure: true, sameSite: 'strict'});
};

export const getIsAuthDataPresent = (): boolean => {
  const userRefreshToken = cookies.get(USER_REFRESH_TOKEN_COOKIE);
  const userToken = cookies.get(USER_TOKEN_COOKIE);
  return !!userRefreshToken && !!userToken;
};
