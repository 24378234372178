import { NavigationItem } from 'interfaces/uiConstants';
import { NavigationCard } from 'interfaces/uiConstants';

import personalInfo from 'static/icons/myAccount/personal-info.svg'
import loginSecurity from 'static/icons/myAccount/login-security.svg'
import billing from 'static/icons/myAccount/billing.svg'
import companyInfo from 'static/icons/myAccount/company-info.svg'
import companySettings from 'static/icons/myAccount/settings.svg'
import notifications from 'static/icons/myAccount/notifications.svg'
import privacySharing from 'static/icons/myAccount/privacy-sharing.svg'
import teamMembers from 'static/icons/myAccount/team-members.svg'
import termsContracts from 'static/icons/myAccount/terms-contracts.svg'
import connections from 'static/icons/myAccount/connections.svg'

export const navigationList: NavigationItem[] = [
  {
    name: 'Personal Information',
    paths: ['/profile'],
  },
  {
    name: 'Company Information',
    paths: ['/company'],
  },
  {
    name: 'Team Members',
    paths: ['/team'],
  },
  {
    name: 'Company Settings',
    paths: ['/settings'],
  },
  {
    name: 'Terms and Contracts',
    paths: ['/terms'],
  },
];

export const navigationCards: NavigationCard[] = [
  {
    icon: personalInfo,
    title: 'Personal Information',
    description: 'Provide personal details and how your team can reach you.',
    path: '/profile'
  },
  // {
  //   icon: loginSecurity,
  //   title: 'Login & Security',
  //   description: 'Update your password and secure your account',
  //   path: '/security'
  // },
  // {
  //   icon: billing,
  //   title: 'Billing',
  //   description: 'Review payments, payouts and subscriptions',
  //   path: '/billing',
  //   disabled: true
  // },
  {
    icon: companyInfo,
    title: 'Company Information',
    description: 'Please share your company information to help the licensors recognize you.',
    path: '/company'
  },
  {
    icon: companySettings,
    title: 'Settings',
    description: 'View your linked licensors and 3rd party partners',
    path: '/settings'
  },
  // {
  //   icon: notifications,
  //   title: 'Notifications',
  //   description: 'Choose notification preferences and how you want to be contacted',
  //   path: '/notifications',
  //   disabled: true
  // },
  // {
  //   icon: privacySharing,
  //   title: 'Privacy & Sharing',
  //   description: 'Manage, invite and edit your team permissions',
  //   path: '/privacy',
  //   disabled: true
  // },
  {
    icon: teamMembers,
    title: 'Team Members',
    description: 'Manage, invite and edit your team permissions',
    path: '/team'
  },
  {
    icon: termsContracts,
    title: 'Terms and Contracts',
    description: 'This page offers downloadable copies of the terms and agreements.',
    path: '/terms'
  },
  // {
  //   icon: connections,
  //   title: 'My Connections',
  //   description: 'View your linked licensors and 3rd party partners',
  //   path: '/connections',
  //   disabled: true
  // },
];
