import { localConfig, stagingConfig, testingConfig, developmentConfig, liveConfig, Config } from './env-configs';

declare let process: { env: { REACT_APP_STAGE: string } };

const configMap: {
  [key: string]: Config;
} = {
  development: developmentConfig,
  local: localConfig,
  staging: stagingConfig,
  testing: testingConfig,
  live: liveConfig,
};

const config = configMap[process.env.REACT_APP_STAGE] || developmentConfig;
export default config;
