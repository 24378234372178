import { OrganisationUpdateEntity } from 'api/organisation';
import { trimAllStringProps } from 'helpers/string';
import Organisation from 'interfaces/organisation';
import { Dispatch } from 'react';
import { updateOrganisation } from 'redux/features/organisation';
import { OrganisationFormEntity } from './types';

const getStringStreet = (street: string | string[]): string => (Array.isArray(street) ? street.join(', ') : street);

export const getOrganisationFormEntity = (organisation: Organisation): OrganisationFormEntity => {
  const {
    company_registration_number,
    company_number,
    business_unit,
    uk_vat,
    phone,
    address,
    billing_address,
    legal_company_name,
    contacts,
  } = organisation.information;

  return {
    legal_company_name,
    company_number,
    company_registration_number,
    business_unit,
    uk_vat,
    phone,
    address: {
      ...address,
      street: address && address.street ? getStringStreet(address.street) : '',
    },
    billing_address: { ...billing_address, street: billing_address ? getStringStreet(billing_address.street) : '' },
    contacts: {
      primary_ambassador: contacts?.primary_ambassador || {},
    },
  };
};

export const getOrgnisationUpdateEntity = (
  organisationFormEntity: OrganisationFormEntity
): OrganisationUpdateEntity => ({
  ...organisationFormEntity,
  address: {
    ...organisationFormEntity.address,
    street: organisationFormEntity.address.street ? [organisationFormEntity.address.street || ''] : [],
  },
  billing_address: {
    ...organisationFormEntity.billing_address,
    street: organisationFormEntity.billing_address ? [organisationFormEntity.billing_address.street || ''] : [],
  },
});

export const onValueSubmit = (organisation: OrganisationFormEntity, dispatch: Dispatch<unknown>): void => {
  const valuesTrimmed = trimAllStringProps(organisation);
  const organisationUpdateEntity = getOrgnisationUpdateEntity(valuesTrimmed);
  dispatch(updateOrganisation(organisationUpdateEntity));
};
