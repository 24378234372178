import {createSlice} from '@reduxjs/toolkit';
import {SortDirection} from '../enums';
import {createTableSliceReturnType, TableState} from './types';

export const createTableSlice = (sliceName: string): createTableSliceReturnType => {
    const initialState: TableState = {
        sort: {fieldKey: '', direction: SortDirection.desc},
        filters: {},
        pagination: {pageNumber: 0, rowsPerPage: 20},
    };

    const tableSlice = createSlice({
        name: sliceName,
        initialState,
        reducers: {
            setRowsPerPage: (state, action) => {
                state.pagination = {
                    rowsPerPage: action.payload,
                    pageNumber: 0,
                };
            },
            setPageNumber: (state, action) => {
                state.pagination.pageNumber = action.payload;
            },
            setSort: (state, action) => {
                const fieldKey = action.payload;
                const {fieldKey: previousFieldKey, direction: previousDirection} = state.sort;
                const direction =
                    previousFieldKey === fieldKey
                        ? previousDirection === SortDirection.desc
                            ? SortDirection.asc
                            : SortDirection.desc
                        : SortDirection.desc;
                const tempFieldKey = direction === SortDirection.desc && previousFieldKey !== '' ? '' : fieldKey;

                state.sort = {direction, fieldKey: tempFieldKey};
            },
            setFilter: (state, action) => {
                state.filters[action.payload.fieldKey] = action.payload.value;
                state.pagination.pageNumber = 0;
            },
            resetFilters: (state) => {
                state.filters = {};
            },
        },
    });

    const {setRowsPerPage, setPageNumber, setSort, setFilter, resetFilters} = tableSlice.actions;

    return {
        actions: {
            setRowsPerPage,
            setPageNumber,
            setSort,
            setFilter,
            resetFilters,
        },
        reducer: tableSlice.reducer,
    };
};

export type TableSliceType = ReturnType<typeof createTableSlice>;
