import React from 'react';
import styles from './TablePagination.module.scss';
import classNames from 'classnames';
import TableRowsNumber from './components/TableRowsNumber/TableRowsNumber';
import { getPaging } from './helpers';

const MAX_VISIBLE_PAGES = 7;

interface Props {
    pageNumber: number;
    rowsPerPage: number;
    totalRows: number;
    availableNumbersOfRows: number[];
    onPageSelected: (pageNumber: number) => void;
    onNumberOfRowsSelected: (rowNumber: number) => void;
}

function TablePagination({
    pageNumber,
    rowsPerPage,
    totalRows,
    onPageSelected,
    availableNumbersOfRows,
    onNumberOfRowsSelected,
}: Props): React.ReactElement {
    const { startPage, endPage, totalPages } = getPaging({
        pageNumber,
        rowsPerPage,
        totalRows,
        maxVisiblePages: MAX_VISIBLE_PAGES,
    });

    return (
        <div className={styles.root}>
            {totalRows > rowsPerPage && (
                <div className={styles.pagination}>
                    <button
                        onClick={() => onPageSelected(0)}
                        disabled={pageNumber === 0}
                        className={classNames(styles.paginationButton)}
                    >
                        First
                    </button>

                    <button
                        onClick={() => onPageSelected(pageNumber - 1)}
                        disabled={pageNumber === 0}
                        className={styles.paginationButton}
                    >
                        Previous
                    </button>

                    <div className={styles.pageNumberButtons}>
                        {Array(endPage - startPage)
                            .fill(0)
                            .map((_el, i) => (
                                <button
                                    onClick={() => onPageSelected(startPage + i)}
                                    key={startPage + i}
                                    className={classNames(styles.paginationButton, styles.numberButton, {
                                        [styles.active]: pageNumber === startPage + i,
                                    })}
                                >
                                    {startPage + i + 1}
                                </button>
                            ))}
                    </div>

                    <button
                        onClick={() => onPageSelected(pageNumber + 1)}
                        disabled={pageNumber === totalPages - 1 || totalPages === 0}
                        className={styles.paginationButton}
                    >
                        Next
                    </button>

                    <button
                        onClick={() => onPageSelected(totalPages - 1)}
                        disabled={pageNumber === totalPages - 1 || totalPages === 0}
                        className={styles.paginationButton}
                    >
                        Last
                    </button>
                </div>
            )}

            <TableRowsNumber
                rowsPerPage={rowsPerPage}
                onNumberOfRowsSelected={onNumberOfRowsSelected}
                availableNumbersOfRows={availableNumbersOfRows}
            />
        </div>
    );
}

export default TablePagination;
