import { createSlice } from '@reduxjs/toolkit';
import { DataState } from 'constants/dataState';
import { inviteUser } from './thunks';
import { MyProfilePageState } from './types';

const initialState: MyProfilePageState = {
  userInviteFetchingState: DataState.Success,
  errorMessage: null,
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    clearError(state) {
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(inviteUser.rejected, (state, action) => {
      state.errorMessage = action.payload;
    });
  },
});

export const { clearError } = teamSlice.actions;

export default teamSlice.reducer;
