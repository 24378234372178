import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import styles from './Company.module.scss';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import { getCurrentOrganisation, setCurrentOrgId } from 'redux/features/organisation';
import { useDispatch, useSelector } from 'react-redux';
import { BusinessInfo } from './components/BusinessInfo';
import { MainOfficeAddress } from './components/MainOfficeAddress';
import { PrimaryContact } from './components/PrimaryContact';
import { Billing } from './components/Billing';
import { getOrganisationFormEntity, onValueSubmit } from './helpers';
import { Form, Formik } from 'formik';
import jwtDecode from "jwt-decode";
import cookie from "js-cookie";
import { USER_TOKEN_COOKIE } from "../../constants/authConstants";
import { fetchToken } from "../../api/user";
import NavLinks from 'components/NavLinks/NavLinks';
import { setAccountPath } from 'redux/features/accountPath';

const CompanyComponent: FC = () => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const currentOrganisation = useSelector(getCurrentOrganisation);
    const company = currentOrganisation ? getOrganisationFormEntity(currentOrganisation) : null;

    const onFormValueSubmit = useCallback(
        (value) => {
            setIsSubmitting(true);
            onValueSubmit(value, dispatch);
        },
        [dispatch]
    );
    const currentUserTokenData: any = jwtDecode(cookie.get(USER_TOKEN_COOKIE) || '');

    useEffect(() => {
        return () => {
            dispatch(setAccountPath({ path: '/', label: 'My Account' }))
        }
    }, [])

    useEffect(() => {
        if ((!currentUserTokenData.data && !currentUserTokenData.data.sub) && currentOrganisation) {
            const orgId = currentOrganisation.organisation_id;
            dispatch(setCurrentOrgId(orgId));
            setTimeout(() => {
                fetchToken().then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 22)
                });
            }, 22)
        }

        setIsSubmitting(false);
    }, [currentOrganisation]);

    return (
        <div className={styles.root}>
            <div className={styles.companyPageContent}>
                <PageBlock>
                    <NavLinks />
                    {company && currentOrganisation && (
                        <Formik enableReinitialize initialValues={company}
                            onSubmit={(values) => onFormValueSubmit(values)}>
                            {({ dirty, values }) => (
                                <Form className={styles.form}>
                                    <BusinessInfo values={values} />
                                    <Billing values={values} />
                                    <MainOfficeAddress values={values} />
                                    <PrimaryContact values={values} />
                                </Form>
                            )}
                        </Formik>
                    )}
                    {(isSubmitting || !company) && <LoadingOverlay />}
                </PageBlock>
            </div>
        </div>
    );
};

export const Company = memo(CompanyComponent);
