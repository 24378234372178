import Button from 'shared/components/Button/Button';
import React from 'react';
import styles from './ErrorView.module.scss';

interface State {
  hasError: boolean;
}

interface Props {
  children: React.ReactNode;
}

export class ErrorView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(): void {
    this.setState({ hasError: true });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className={styles.root}>
          <h1>Something went wrong</h1>
          <h2 className={styles.subtitle}>Please, refresh the page to continue</h2>
          <Button large action onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}
