import React, {FC, memo, useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';

import {useQuery} from 'shared/hooks/useQuery';
import {RegisterForm} from './components/RegisterForm';
import Button from 'shared/components/Button/Button';
import {clearAuthData, getIsAuthDataPresent, navigateToLoginOnRegister} from 'helpers/auth';
import {ReactComponent as SuccessIcon} from 'static/icons/checkbox.svg';
import {RootState} from 'redux/store';

import styles from './RegisterUser.module.scss';
import classnames from "classnames";
import { ReactComponent as InfoIcon } from 'static/icons/info.svg';

const RegisterUserComponent: FC = () => {
    const query = useQuery();
    const history = useHistory();
    const [isUserAlreadyLoggedIn, setIsUserAlreadyLoggedIn] = useState(false);
    const isRegistrationComplete = useSelector<RootState, boolean>(
        (state) => state.registerUserPage.isRegistrationComplete
    );
    const [currentWizardStep, setCurrentWizardStep] = useState(1);
    const [isChrome, setIsChrome] = useState(true);

    useEffect(() => {
        const isAuthDataPresent = getIsAuthDataPresent();
        setIsUserAlreadyLoggedIn(isAuthDataPresent);
    }, [setIsUserAlreadyLoggedIn]);

    useEffect(() => {
        const isChromeBrowser = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        setIsChrome(isChromeBrowser);
    }, []);

    const redirectToSignIn = useCallback(() => {
        navigateToLoginOnRegister();
    }, []);

    const logout = useCallback(() => {
        clearAuthData();
        setIsUserAlreadyLoggedIn(false);
    }, [setIsUserAlreadyLoggedIn]);

    const handleWizardChange = (value: number) => {
        setCurrentWizardStep(value);
    };
    return (
        <div className={styles.root}>
            <div className={styles.logoContainer}>
                <img src="/img/xelacore-logo-full.svg" alt="logo" className={styles.logoImg}/>
            </div>
            <div className={styles.card}>
                {!isRegistrationComplete ? (
                    <>
                        <div className={styles.wizardIndicatorWrapper}>

                            <h6>{currentWizardStep} / 2</h6>
                            <div className={styles.wizardIndicatorList}>
                                <div onClick={() => setCurrentWizardStep(1)}
                                     className={classnames(styles.wizardIndicatorItem, styles.clickable, {[styles.active]: currentWizardStep > 0})}></div>
                                <div
                                    className={classnames(styles.wizardIndicatorItem, {[styles.active]: currentWizardStep > 1})}></div>
                            </div>
                        </div>

                        <h2 className={styles.heading}>You've been invited to Xelacore!</h2>
                        {currentWizardStep === 1 ?
                            (<h4 className={styles.subHeading}>
                                    Please fill in the fields below to create your account.
                                </h4>
                            ) :
                            (<h4 className={styles.subHeading}>
                                Please read and accept our Terms of Service to create your account.
                            </h4>)
                        }
                        {isUserAlreadyLoggedIn ? (
                            <div className={styles.logoutContainer}>
                                <h2 className={styles.heading}>You are signed in the system currently</h2>
                                <h4 className={styles.subHeading}>You need to sign out if you wish to continue
                                    registration</h4>
                                <Button onClick={logout} large action>
                                    Sign out
                                </Button>
                            </div>
                        ) : (
                            <div className={styles.formHolder}>
                                <RegisterForm currentWizardStep={currentWizardStep}
                                              onWizardChange={handleWizardChange}></RegisterForm>
                            </div>
                        )}
                    </>
                ) : (
                    <div className={styles.logoutContainer}>
                        <SuccessIcon className={styles.successIcon}></SuccessIcon>
                        <h2 className={styles.heading}>Registration complete!</h2>
                        <Button onClick={redirectToSignIn} large action>
                            Continue to Sign In
                        </Button>
                    </div>
                )}

                {!isChrome&&(
                    <p className={styles.infoMessage}>
                        <InfoIcon></InfoIcon>
                        <span>Tip! Make sure you’re using Chrome for a better experience.</span>

                    </p>
                )}
            </div>
        </div>
    );
};

export const RegisterUser = memo(RegisterUserComponent);
