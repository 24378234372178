import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Security.module.scss';
import { User } from 'interfaces/user';
import { getUser } from 'redux/features/user';
import { RootState } from 'redux/store';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import PasswordInfo from './components/PasswordInfo/PasswordInfo';
import Button from 'shared/components/Button/Button';
import { Form, Formik } from 'formik';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import NavLinks from 'components/NavLinks/NavLinks';
import { setAccountPath } from 'redux/features/accountPath';

const SecurityComponent: FC = () => {
    const dispatch = useDispatch();
    const user = useSelector<RootState, User | null>((state) => state.user.user);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(setAccountPath({ path: '/', label: 'My Account' }))
        }
    }, [])

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        setIsSubmitting(false);
    }, [user]);

    return (
        <div className={styles.root}>
            <PageBlock>
                <NavLinks />
                {user && (
                    <Formik
                        enableReinitialize
                        initialValues={user}
                        onSubmit={(values) => {
                            // dispatch(updateUser({ user: values }));
                        }}
                    >
                        {({ dirty, values }) => (
                            <Form className={styles.form}>
                                <PasswordInfo values={values} />

                                <div className={styles.formActions}>
                                    <Button
                                        type="submit"
                                        action
                                        large
                                        className={styles.submitButton}
                                        disabled={isSubmitting || !dirty}
                                    >
                                        Update Password
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
                {(isSubmitting || !user) && <LoadingOverlay />}
            </PageBlock>
        </div>
    );
};

export const Security = memo(SecurityComponent);
