import httpClient from 'http/httpClient';
import baseHttpClient from 'http/baseHttpClient';
import config from '../../config/env-config';
import {User} from 'interfaces/user';
import {RegisterUserEntity} from 'redux/features/user/types';
import {InviteUserResponse, UpdateUserResponse} from '.';
import {FetchUserResponse} from './interface';
import cookie from 'js-cookie';
import {logout} from 'helpers/auth';

import {
    GRANT_TYPE,
    ORG_ID_COOKIE,
    SCOPE,
    USER_REFRESH_TOKEN_COOKIE,
    USER_TOKEN_COOKIE
} from '../../constants/authConstants';
import {serializeObject} from '../../helpers/serializeObject';

export const fetchUser = (): Promise<{ data: FetchUserResponse }> =>
    httpClient.get('/v1/auth/user').then(({data}) => data);

export const fetchToken = () => {
    const refreshToken = cookie.get(USER_REFRESH_TOKEN_COOKIE) || '';
    const organisationId = cookie.get(ORG_ID_COOKIE) || '';

    if (!refreshToken || !organisationId) {
        logout();
    }
    const formData = serializeObject({
        refresh_token: refreshToken,
        organisation_id: organisationId,
        grant_type: GRANT_TYPE,
        client_id: config.client_id,
        redirect_uri: config.redirect_uri,
        scope: SCOPE,
    });

    return httpClient.post('/oauth/token', formData).then(({data}) => {
        cookie.set(USER_TOKEN_COOKIE, data.access_token, {
            domain: config.appDomain,
            secure: true,
            sameSite: 'strict'
        });
        cookie.set(USER_REFRESH_TOKEN_COOKIE, data.refresh_token, {
            domain: config.appDomain,
            secure: true,
            sameSite: 'strict'
        });
        return data
    });
};

export const patchUserAndUserImage = (
    user: Partial<User>,
    image?: File | null
): Promise<{ data: UpdateUserResponse }> => {
    const promiseArray = [];
    const patchUserRequest = httpClient.patch('/v1/auth/user/details', user.information);
    promiseArray.push(patchUserRequest);
    if (image) {
        const formData = new FormData();
        formData.append('image', image);
        const postUserImageRequest = httpClient.post('/v1/auth/user/image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        promiseArray.push(postUserImageRequest);
    }
    return Promise.all(promiseArray).then(([{data}]) => data);
};

// @todo Add a new user data type instead of params: any
export const postInviteUser = (orgId: string, params: any): Promise<InviteUserResponse> =>
    httpClient
        .post(`/v1/auth/organisations/${orgId}/employees`, {
            email_parameters: {
                web_url: config.services.login
            },
            invites: [{
                information: {
                    first_name: params.firstName,
                    last_name: params.lastName
                },
                email: params.email,
                role_id: '88c0a3ca-cbce-4b27-b390-f6a001ca129f'
            }]
        })
        .then(({data}) => data)
        .catch((err) => {
            throw err.response.data;
        });

export const postRegisterUser = (registerUserEntity: RegisterUserEntity): Promise<void> =>
    baseHttpClient
        .post(`/v1/auth/user/register`, registerUserEntity, { withCredentials: true })
        .then(({data}) => data)
        .catch((err) => {
            throw err && err.response && err.response.data || `Oops, something went wrong. Please try again. (${err})`;
        });
