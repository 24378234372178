import React, { FC, memo } from 'react';
import styles from './RegistrationProject.module.scss';

interface Props {
    aliases: string[];
    gcpList: string[];
    organisationName: string;
    gtinWaiver: boolean;
}

const RegistrationProjectComponent: FC<Props> = ({ aliases, gcpList, organisationName, gtinWaiver }) => {
    return (
        <div className={styles.root}>
            <h2 className={styles.title}>Doing Business As</h2>
            <div className={styles.row}>
                {aliases.map((alias, aliasIndex) => (
                    <span key={aliasIndex}>{alias}</span>
                ))}
            </div>
            <div className={styles.divider} />

            <h2 className={styles.title}>GCP</h2>
            <div className={styles.row}>
                {gcpList.map((gcp, gcpIndex) => (
                    <span key={gcpIndex}>{gcp}</span>
                ))}
            </div>
            <div className={styles.divider} />

            {/* <h2 className={styles.title}>GTIN Settings</h2> */}
            <h3 className={styles.title}>GTIN Extempt?</h3>
            <div className={styles.row}>
                <span>{gtinWaiver ? 'Yes' : 'No'} </span>
            </div>
            <div className={styles.divider} />
        </div>
    );
};

export const RegistrationProject = memo(RegistrationProjectComponent);
