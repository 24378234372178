import React, { FC, memo, useEffect, useState } from 'react';
import styles from './NavLinks.module.scss';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as AngleIcon } from 'static/icons/angle-right.svg';
import { setAccountPath } from 'redux/features/accountPath';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';


const NavLinks: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  useEffect(() => history.listen(({ pathname }) => setCurrentPath(pathname)), [history]);

  const path = useSelector<RootState, string>((state) => state.accountPath.path);
  const pathTitle = useSelector<RootState, string>((state) => state.accountPath.label);

  return (
    <div className={styles.root}>
      {path !== '/' && (
        <div className={styles.navigationContainer}>
          <Link
            className={classNames(styles.navLink, {
              [styles.active]: currentPath === '/'
            })}
            onClick={() => dispatch(setAccountPath({ path: '/', label: 'My Account' }))}
            to={'/'}
          >
            My Account
          </Link>

          <AngleIcon />
          
          <div
            className={classNames(styles.navLink, {
              [styles.active]: currentPath === path
            })}
          >
            {pathTitle}
          </div>
        </div>
      )}
      <p className={styles.pageTitle}>{pathTitle}</p>
    </div>
  );
};

export default memo(NavLinks);
