import {createSlice} from '@reduxjs/toolkit';
import merge from 'lodash/merge';

import {getEmployees, getOrganisations, updateOrganisation} from './thunks';
import {OrganisationState} from './types';
import {ORG_ID_COOKIE} from '../../../constants/authConstants';
import cookies from 'js-cookie';
import config from '../../../config/env-config';

const initialState: OrganisationState = {
    organisations: [],
    employees: [],
    currentOrgId: null,
    currentOrgInfo: null,
};

export const organisationSlice = createSlice({
    name: 'organisation',
    initialState,
    reducers: {
        setCurrentOrgId(state, action) {
            state.currentOrgId = action.payload;
        },
        setCurrentOrgInfo(state, action) {
            state.currentOrgInfo = action.payload;
        },
        setOrganisations(state, action) {
            state.organisations = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOrganisations.fulfilled, (state, action) => {
            state.organisations = action.payload.organisations;
            const organisationId = cookies.get(ORG_ID_COOKIE);
            const filteredOrgLicensors = action.payload.organisations.map(organisation => organisation.organisation_id);
            if (!!organisationId && !filteredOrgLicensors.includes(organisationId)) {
                state.currentOrgId = null;
                cookies.remove(ORG_ID_COOKIE, {domain: config.appDomain, secure: true, sameSite: 'strict'});
            }
        });
        builder.addCase(updateOrganisation.fulfilled, (state, action) => {
            const currentOrganisation = state.organisations.find(
                ({organisation_id}) => organisation_id === state.currentOrgId
            );
            if (!currentOrganisation) {
                return;
            }
            currentOrganisation.information = merge(currentOrganisation.information, action.payload);
        });
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            state.employees = action.payload.filter(employee => !!employee.email && !employee.email?.endsWith('@fabacus.com'));
        });
    },
});

export const {setCurrentOrgId, setCurrentOrgInfo, setOrganisations} = organisationSlice.actions;

export default organisationSlice.reducer;
