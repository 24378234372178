import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Button.module.scss';
import classNames from 'classnames';

const buttonTypeMap = {
  primary: styles.primary,
  action: styles.action,
  text: styles.text,
  danger: styles.danger,
  danger2: styles.danger2,
};

const buttonSizeMap = {
  large: styles.large,
  small: styles.small,
  medium: styles.medium,
};

interface Props {
  primary?: boolean;
  action?: boolean;
  text?: boolean;
  danger?: boolean;
  danger2?: boolean;

  large?: boolean;
  medium?: boolean;
  small?: boolean;

  to?: string;
  onClick?: () => void;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  children: React.ReactNode;
  download?: boolean;
  disabled?: boolean;
  id?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export default function Button({
  action = false,
  text = false,
  danger = false,
  danger2 = false,

  large = false,
  small = false,
  to = '',
  id,
  onClick,
  children,
  disabled,
  iconLeft,
  iconRight,
  className,
  download,
  type = 'button',
}: Props): React.ReactElement {
  const buttonStyleType = action ? 'action' : text ? 'text' : danger ? 'danger' : danger2 ? 'danger2' : 'primary';
  const size = large ? 'large' : small ? 'small' : 'medium';

  const content = (
    <React.Fragment>
      {iconLeft !== undefined && <div className={classNames(styles.iconHolder, styles.left)}>{iconLeft}</div>}
      <span>{children}</span>
      {iconRight !== undefined && <div className={classNames(styles.iconHolder, styles.right)}>{iconRight}</div>}
    </React.Fragment>
  );

  const link = download ? (
    <a
      className={classnames(
        styles.root,
        {
          [buttonTypeMap[buttonStyleType]]: buttonStyleType,
          [buttonSizeMap[size]]: size,
          [styles.disabled]: disabled,
        },
        className
      )}
      id={id}
      download
      href={to}
    >
      {content}
    </a>
  ) : (
    <Link
      className={classnames(
        styles.root,
        {
          [buttonTypeMap[buttonStyleType]]: buttonStyleType,
          [buttonSizeMap[size]]: size,
          [styles.disabled]: disabled,
        },
        className
      )}
      id={id}
      to={to}
      onClick={onClick}
    >
      {content}
    </Link>
  );

  const button = (
    <button
      className={classnames(
        styles.root,
        {
          [buttonTypeMap[buttonStyleType]]: buttonStyleType,
          [buttonSizeMap[size]]: size,
          [styles.disabled]: disabled,
        },
        className
      )}
      id={id}
      onClick={onClick}
      disabled={!!disabled}
      type={type}
    >
      {content}
    </button>
  );

  return to ? link : button;
}
