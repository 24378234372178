import { createSlice } from '@reduxjs/toolkit';
import { registerUser } from 'redux/features/user';
import { RegisterUserPageSlice } from './types';

const initialState: RegisterUserPageSlice = {
  errorMessage: undefined,
  isRegistrationComplete: false,
};

export const slice = createSlice({
  name: 'registerUserPage',
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.fulfilled, (state) => {
      state.isRegistrationComplete = true;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    });
  },
});

export const { setErrorMessage } = slice.actions;

export default slice.reducer;
