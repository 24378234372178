import { useSelector } from 'react-redux';
import { xelacoreModuleDefinitions } from 'constants/xelacoreModules';
import { XelacoreModuleDefinition } from 'interfaces/xelacoreModuleDefinition';
import { getCurrentOrganisation } from 'redux/features/organisation';

export const useXelacoreModules = (): XelacoreModuleDefinition[] => {
  const currentOrganisation = useSelector(getCurrentOrganisation);
  const avaialbleModules = currentOrganisation?.modules || [];
  const mappedAvailableModules = avaialbleModules.filter((module) => xelacoreModuleDefinitions.has(module));
  const availableModulesDefinitions = mappedAvailableModules.map(
    (module) => xelacoreModuleDefinitions.get(module) || { name: '', icon: '', link: '' }
  );
  return availableModulesDefinitions;
};
