import React from 'react';
import {Route} from 'react-router-dom';

import {useAuth} from '../../hooks/useAuth';
import OrganisationSelect from '../../views/OrganisationSelect/OrganisationSelect';
import Layout from '../Layout/Layout';

interface Props {
    children: React.ReactNode;
    path: string | string[];
    exact?: boolean | undefined;
    hideMenu?: boolean;
    hideCover?: boolean;
}

export default function AuthProtectedRoute({children, exact, path, hideMenu = false, hideCover = false}: Props): React.ReactElement {
    const [isAuthenticated, isOrgSelected] = useAuth();
    return isAuthenticated ? (
        isOrgSelected ? (
            <Layout hideMenu={hideMenu} hideCover={hideCover}>
                <Route exact={exact} path={path}>
                    {children}
                </Route>
            </Layout>
        ) : (
            <OrganisationSelect></OrganisationSelect>
        )
    ) : (
        <></>
    );
}
