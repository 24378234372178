import React, {FC, memo, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import md5 from 'md5';


import {ReactComponent as ArrowDown} from 'static/icons/caret-down.svg';

import styles from './DropdownMenu.module.scss';

interface Props {
    title: ReactNode;
    userdata?: {
        imagepresence?: boolean,
        image?: string,
        email?: string
    };
    options: {
        label: ReactNode;
        onClick: () => void;
    }[];
    hasArrow?: boolean;
    menuheader?: string | boolean;
    onToggle?: (isOpened: boolean) => void;
}

const DropdownMenuComponent: FC<Props> = ({title, userdata, options, hasArrow = true, menuheader = false, onToggle = noop}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const closeDropdown = (e: MouseEvent) => {
        if (titleRef.current && e && e.target && e.target === titleRef.current) {
            return;
        }
        setIsMenuOpen(false);
    };
    const titleRef = useRef(null);
    const useremail = userdata && userdata.email ? userdata.email : '';
    const userimage = userdata && userdata.image ? userdata.image : '';
    const UserPlaceholder = `//www.gravatar.com/avatar/${md5(useremail.toString())}?s=50&d=identicon`;


    const onTitleClick = useCallback(() => {
        setIsMenuOpen((value) => !value);
    }, [setIsMenuOpen, onToggle]);

    useEffect(() => {
        onToggle(isMenuOpen);
    }, [isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('click', closeDropdown);
        }
        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, [isMenuOpen]);

    return (
        <div className={styles.root}>
            <div ref={titleRef} className={styles.menuTitle} onClick={onTitleClick}>
                {title}
                {hasArrow && (
                    <ArrowDown className={classNames(styles.arrowIcon, {[styles.inverted]: isMenuOpen})}></ArrowDown>
                )}
                {userdata && userdata.imagepresence && (
                    <img
                        className={styles.userImage}
                        src={userimage}
                        onError={(e) => ((e.target as HTMLImageElement).src = UserPlaceholder)}/>
                )}
            </div>
            <div className={classNames(styles.optionsHolder, {[styles.open]: isMenuOpen})}>
                {menuheader && (
                    <h4 className={styles.dropdownHeader}>{menuheader}</h4>
                )}
                {options.map(({label, onClick}, i) => (
                    <div key={i} className={styles.option} onClick={onClick}>
                        {label}
                    </div>
                ))}
            </div>
        </div>
    );
};

export const DropdownMenu = memo(DropdownMenuComponent);
