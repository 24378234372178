import React, { FC, memo, useState } from 'react';

import styles from './MainInfo.module.scss';
import InputField from 'shared/components/InputField/InputField';
import Input from 'shared/components/Input/Input';
import { updateUser } from 'redux/features/user';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

interface Props {
    values: { [key: string]: any },
}

const MainInfo: FC<Props> = ({ values }) => {
    const dispatch: AppDispatch = useDispatch();
    const { email, information: { job_title, phone_number, first_name, last_name } } = values;

    const [data, setData] = useState({
        full_name: `${first_name} ${last_name}`,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
        job_title: job_title
    })
    const [editField, setEditField] = useState({
        first_name: false,
        last_name: false,
        phone_number: false,
        job_title: false,
        full_name: false
    });

    const handleSubmit = (data: any, value?: any, group?: boolean) => {
        dispatch(updateUser({
            user: {
                ...values,
                information: group
                    ? {
                        ...values.information,
                        first_name: data.first_name,
                        last_name: data.last_name
                    }
                    : {
                        ...values.information,
                        [value]: data
                    }
            }
        })).then((data) => {
            if (data) {
                setEditField(prev => ({ ...prev, [value]: false }))
            }
        })
    }

    return (
        <div className={styles.root}>
            <div className={styles.fieldsContainer}>
                <div className={styles.pairedFieldsContainer}>
                    <InputField
                        fieldName="Legal Name"
                        value={`${first_name} ${last_name}`}
                        submitAction={() => handleSubmit({first_name: data.first_name, last_name: data.last_name}, 'full_name', true)}
                        editField={editField.full_name}
                        setEditField={() => setEditField(prev => ({ ...prev, full_name: !editField.full_name }))}
                    >
                        <div className={styles.row}>
                            <Input
                                name="information.first_name"
                                formikField
                                value={data.first_name}
                                onChange={(e) => setData(prev => ({ ...prev, first_name: e.target.value }))}
                            />
                            <Input
                                name="information.last_name"
                                formikField
                                value={data.last_name}
                                onChange={(e) => setData(prev => ({ ...prev, last_name: e.target.value }))}
                            />
                        </div>
                    </InputField>
                </div>

                <InputField fieldName="Email" value={email} disabled>
                    <Input disabled name="email" formikField />
                </InputField>

                <InputField
                    fieldName="Mobile Number"
                    value={data.phone_number}
                    submitAction={() => handleSubmit(data.phone_number, 'phone_number', false)}
                    editField={editField.phone_number}
                    setEditField={() => setEditField(prev => ({ ...prev, phone_number: !editField.phone_number }))}
                >
                    <Input
                        name="phone_number"
                        formikField
                        value={data.phone_number}
                        onChange={(e) => setData(prev => ({ ...prev, phone_number: e.target.value }))}
                    />
                </InputField>

                <InputField
                    fieldName="Job Title"
                    value={data.job_title}
                    submitAction={() => handleSubmit(data.job_title, 'job_title', false)}
                    editField={editField.job_title}
                    setEditField={() => setEditField(prev => ({ ...prev, job_title: !editField.job_title }))}
                >
                    <Input
                        name="job_title"
                        formikField
                        value={data.job_title}
                        onChange={(e) => setData(prev => ({ ...prev, job_title: e.target.value }))}
                    />
                </InputField>
            </div>
        </div>
    );
};

export default memo(MainInfo);
