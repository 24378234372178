import React, { FC, memo } from 'react';
import styles from './LoadingOverlay.module.scss';

const LoadingOverlay: FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.loader}></div>
    </div>
  );
};

export default memo(LoadingOverlay);
