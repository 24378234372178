import React, { FC, memo } from 'react';
import { Option } from '../Dropdown/types';
import Dropdown from '../Dropdown/Dropdown';
import { Field, FieldProps } from 'formik';

interface Props {
  isSearchable?: boolean;
  name: string;
  options: Option[];
  className?: string;
  error?: string | null;
  isMulti?: boolean;
  isDisabled?: boolean;
}

const FormikDropdownComponent: FC<Props> = (props) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => {
        return (
          <Dropdown
            {...props}
            value={field.value}
            onChange={(value) => form.setFieldValue(props.name as string, value)}
          ></Dropdown>
        );
      }}
    </Field>
  );
};

export const FormikDropdown = memo(FormikDropdownComponent);
