import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBrands, fetchFranchises, fetchCharacters } from 'api/brand';
import { RootState } from 'redux/store';

export const getBrands = createAsyncThunk('brandsPage/getBrands', async (_, { getState }) => {
  const {
    organisation: { currentOrgId },
  } = getState() as RootState;
  return currentOrgId ? await fetchBrands(currentOrgId) : [];
});

export const getFranchises = createAsyncThunk('brandsPage/getFranchises', async (_, { getState }) => {
  const {
    organisation: { currentOrgId: orgId },
    brandsPage: { selectedBrandId: brandId },
  } = getState() as RootState;
  if (!orgId || !brandId) {
    return [];
  }
  return fetchFranchises({ orgId, brandId });
});

export const getCharacters = createAsyncThunk('brandsPage/getCharacters', async (_, { getState }) => {
  const {
    organisation: { currentOrgId: orgId },
    brandsPage: { selectedBrandId: brandId, selectedFranchiseId: franchiseId },
  } = getState() as RootState;
  if (!orgId || !brandId || !franchiseId) {
    return [];
  }
  return await fetchCharacters({ orgId, brandId, franchiseId });
});
