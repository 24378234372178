import React, { FC, memo } from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';
import { ReactComponent as OfficeAddressIcon } from 'static/icons/address-icon.svg';

import { FormikDropdown } from 'shared/components/FormikDropdown';
import { countries } from 'constants/countries';
import styles from '../../Company.module.scss';

interface Props {
  values: { [key: string]: any }
}

const MainOfficeAddressComponent: FC<Props> = ({ values }) => {
  const {address: {town, region, country, postcode}} = values;

  return (
    <>
      <div className={styles.mainTitle}>
        <OfficeAddressIcon className={styles.titleIcon} />
        <p className={styles.titleText}>Main Office Address</p>
      </div>

      <InputField fieldName="City" value={town} disabled>
        <Input name="address.town" disabled formikField/>
      </InputField>

      <InputField fieldName="Region" value={region} disabled>
        <Input name="address.region" disabled formikField/>
      </InputField>

      <InputField fieldName="Country" value={countries.find(item => item.value === country)?.label} disabled>
        <FormikDropdown isSearchable={true} isDisabled={true} name="address.country" options={countries}/>
      </InputField>

      <InputField fieldName="Postcode / Zipcode" value={postcode} disabled>
        <Input name="address.postcode" disabled formikField/>
      </InputField>
    </>
  );
};

export const MainOfficeAddress = memo(MainOfficeAddressComponent);
