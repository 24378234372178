import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Brand } from 'interfaces/brand';
import { Character } from 'interfaces/character';
import { Franchise } from 'interfaces/franchises';
import { getBrands, getCharacters, getFranchises, setSelectedBrandId, setSelectedFranchiseId } from './redux';
import { ListViewEntry } from './components/ListView/types';

interface ReturnType {
  brandsFilterValue: string;
  franchisesFilterValue: string;
  charactersFilterValue: string;

  brandEntries: ListViewEntry[];
  franchiseEntries: ListViewEntry[];
  characterEntries: ListViewEntry[];

  selectedBrandId: string | null;
  selectedFranchiseId: string | null;

  onBrandClick: (id: string) => void;
  onFranchiseClick: (id: string) => void;

  setBrandsFilterValue: (expr: string) => void;
  setFranchisesFilterValue: (expr: string) => void;
  setCharactersFilterValue: (expr: string) => void;
}

export const useBrandsPage = (): ReturnType => {
  const dispatch = useDispatch();
  const [brandsFilterValue, setBrandsFilterValue] = useState('');
  const [franchisesFilterValue, setFranchisesFilterValue] = useState('');
  const [charactersFilterValue, setCharactersFilterValue] = useState('');

  const currentOrgId = useSelector<RootState>((state) => state.organisation.currentOrgId);

  const brands = useSelector<RootState, Brand[]>((state) => state.brandsPage.brands);
  const franchises = useSelector<RootState, Franchise[]>((state) => state.brandsPage.franchises);
  const characters = useSelector<RootState, Character[]>((state) => state.brandsPage.characters);

  const selectedBrandId = useSelector<RootState, string | null>((state) => state.brandsPage.selectedBrandId);
  const selectedFranchiseId = useSelector<RootState, string | null>((state) => state.brandsPage.selectedFranchiseId);

  useEffect(() => {
    if (currentOrgId) {
      dispatch(getBrands());
    }
  }, [currentOrgId]);

  const onBrandClick = useCallback(
    (id) => {
      dispatch(setSelectedBrandId(id));
    },
    [dispatch]
  );

  const onFranchiseClick = useCallback(
    (id) => {
      dispatch(setSelectedFranchiseId(id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedBrandId) {
      dispatch(getFranchises());
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (selectedFranchiseId) {
      dispatch(getCharacters());
    }
  }, [selectedFranchiseId]);

  useEffect(() => {
    if (brands.length > 0) {
      dispatch(setSelectedBrandId(brands[0].brand_id));
    }
  }, [brands]);

  useEffect(() => {
    if (franchises.length > 0) {
      dispatch(setSelectedFranchiseId(franchises[0].franchise_id));
    }
  }, [franchises]);

  const brandEntries = brands
    .filter(({ brand }) => brand.includes(brandsFilterValue))
    .map(({ brand, brand_id }) => ({ name: brand, id: brand_id }));

  const franchiseEntries = franchises
    .filter(({ franchise }) => franchise.includes(franchisesFilterValue))
    .map(({ franchise, franchise_id }) => ({ name: franchise, id: franchise_id }));

  const characterEntries = characters
    .filter(({ character }) => character.includes(charactersFilterValue))
    .map(({ character, character_id }) => ({ name: character, id: character_id }));

  return {
    brandsFilterValue,
    franchisesFilterValue,
    charactersFilterValue,

    brandEntries,
    franchiseEntries,
    characterEntries,

    selectedBrandId,
    selectedFranchiseId,

    onBrandClick,
    onFranchiseClick,

    setBrandsFilterValue,
    setFranchisesFilterValue,
    setCharactersFilterValue,
  };
};
