import React, { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ReactComponent as AppsIcon } from 'static/icons/apps.svg';

import styles from './ModuleSelection.module.scss';
import { useXelacoreModules } from 'hooks/useXelacoreModules';

const ModuleSelectionComponent: FC = () => {
  const userName = useSelector<RootState, string | undefined>((state) => state.user.user?.information.first_name);
  const availableXelacoreModules = useXelacoreModules();

  useEffect(() => {
    if (availableXelacoreModules.length === 1) {
      if (availableXelacoreModules[0].link) {
        window.location.href = availableXelacoreModules[0].link;
      }
    }
  }, [availableXelacoreModules]);

  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>Welcome {userName}!</h2>
      <p className={styles.text}>
        <AppsIcon className={styles.appsIcon}></AppsIcon>Please choose which module you would like to access.
      </p>
      {availableXelacoreModules && availableXelacoreModules.length > 0 ? (
        <div className={styles.modulesList}>
          {availableXelacoreModules.map(({ name, icon, link }) => (
            <a className={styles.moduleCard} href={link}>
              {icon}
              <span className={styles.moduleName}>{name}</span>
            </a>
          ))}
        </div>
      ) : (
        <div className={styles.modulesList}>
          <p className={styles.text}>You have no access to xelacore modules currently</p>
        </div>
      )}
      <p className={styles.subtitle}>
        You can always jump between modules by clicking on
        <br />
        this icon <AppsIcon className={styles.appsIcon}></AppsIcon> next to your profile picture
      </p>
    </div>
  );
};

export const ModuleSelection = memo(ModuleSelectionComponent);
