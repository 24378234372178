import { createSlice } from '@reduxjs/toolkit';
import { getAliases, getConfig } from './thunks';
import { SettingsPageSlice } from './types';

const initialState: SettingsPageSlice = {
  aliases: [],
  gcpList: [],
  gtinWaiver: false,
};

export const brandsPageSlice = createSlice({
  name: 'settingsPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAliases.fulfilled, (state, action) => {
      state.aliases = action.payload;
    });
    builder.addCase(getConfig.fulfilled, (state, { payload }) => {
      state.gcpList = payload.gcp_list;
      state.gtinWaiver = payload.gtin_waiver;
    });
  },
});

// export const {} = brandsPageSlice.actions;

export default brandsPageSlice.reducer;
