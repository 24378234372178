import { FC, memo } from 'react';

import { ListView } from './components/ListView';
import styles from './Brands.module.scss';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import { useBrandsPage } from './hooks';

const BrandsComponent: FC = () => {
  const {
    brandsFilterValue,
    franchisesFilterValue,
    charactersFilterValue,

    brandEntries,
    franchiseEntries,
    characterEntries,

    selectedBrandId,
    selectedFranchiseId,

    onBrandClick,
    onFranchiseClick,

    setBrandsFilterValue,
    setFranchisesFilterValue,
    setCharactersFilterValue,
  } = useBrandsPage();

  return (
    <div className={styles.root}>
      <PageBlock>
        <div className={styles.content}>
          <div className={styles.listViewContainer}>
            <ListView
              filterValue={brandsFilterValue}
              setFilterValue={setBrandsFilterValue}
              onEntryClick={onBrandClick}
              entries={brandEntries}
              selectedEntryId={selectedBrandId}
              title="Brands"
            ></ListView>
            <ListView
              filterValue={franchisesFilterValue}
              setFilterValue={setFranchisesFilterValue}
              onEntryClick={onFranchiseClick}
              selectedEntryId={selectedFranchiseId}
              entries={franchiseEntries}
              title="Franchises"
            ></ListView>
            <ListView
              filterValue={charactersFilterValue}
              setFilterValue={setCharactersFilterValue}
              entries={characterEntries}
              title="Characters"
            ></ListView>
          </div>
        </div>
      </PageBlock>
    </div>
  );
};

export const Brands = memo(BrandsComponent);
