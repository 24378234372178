import React from 'react';
import Dropdown from 'shared/components/Dropdown/Dropdown';

import styles from './TableRowsNumber.module.scss';

interface Props {
  rowsPerPage: number;
  availableNumbersOfRows: number[];
  onNumberOfRowsSelected: (rowNumber: number) => void;
}

function TableRowsNumber({ rowsPerPage, onNumberOfRowsSelected, availableNumbersOfRows }: Props): React.ReactElement {
  return (
    <div className={styles.root}>
      <span className={styles.showLabel}>Show:</span>

      <Dropdown
        className={styles.numberSelect}
        value={rowsPerPage}
        options={availableNumbersOfRows.map((n) => ({ value: n, label: `${n}` }))}
        onChange={(value) => onNumberOfRowsSelected(+value)}
        isSearchable={false}
      />
    </div>
  );
}

export default TableRowsNumber;
