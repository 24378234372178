import React, { FC, memo } from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';
import { ReactComponent as BillingIcon } from 'static/icons/billing-icon.svg';
import styles from '../../Company.module.scss';

interface Props {
    values: { [key: string]: any }
}

const BillingComponent: FC<Props> = ({ values }) => {
    const { street, town, region, country, postcode } = values.billing_address;

    return (
        <>
            <div className={styles.mainTitle}>
                <BillingIcon className={styles.titleIcon} />
                <p className={styles.titleText}>Billing</p>
            </div>

            <InputField fieldName="Billing Street Address" value={street} disabled>
                <Input disabled name="street" formikField />
            </InputField>

            <InputField fieldName="Billing City" value={town} disabled>
                <Input disabled name="town" formikField />
            </InputField>

            <InputField fieldName="Billing Region" value={region} disabled>
                <Input disabled name="region" formikField />
            </InputField>

            <InputField fieldName="Billing Country" value={country} disabled>
                <Input disabled name="country" formikField />
            </InputField>

            <InputField fieldName="Billing Postcode / Zipcode" value={postcode} disabled>
                <Input disabled name="postcode" formikField />
            </InputField>
        </>
    );
};

export const Billing = memo(BillingComponent);
