export const serializeObject = (obj: { [key: string]: string | number | boolean }): string => {
  const str = Object.entries(obj).reduce(
    (
      acc: (string | number | boolean)[],
      [k, v]: [string, string | number | boolean]
    ): (string | number | boolean)[] => [...acc, encodeURIComponent(k) + '=' + encodeURIComponent(v)],
    []
  );
  return str.join('&');
};
