import {useEffect} from 'react';
import jwtDecode from 'jwt-decode';
import {useDispatch, useSelector} from 'react-redux';
import cookie from 'js-cookie';
import config from '../config/env-config';
import {
    getOrganisations,
    setOrganisations,
    setCurrentOrgId,
    setCurrentOrgInfo,
} from '../redux/features/organisation';
import {
    GRANT_TYPE,
    ORG_ID_COOKIE,
    SCOPE,
    USER_REFRESH_TOKEN_COOKIE,
    USER_TOKEN_COOKIE
} from '../constants/authConstants';
import {RootState} from '../redux/store';
import Organisation from 'interfaces/organisation';
import {OrganisationInfo} from 'redux/features/organisation/types';
import httpClient from '../http/httpClient';
import {serializeObject} from '../helpers/serializeObject';

export const useAuth = (): [boolean, boolean] => {
    const dispatch = useDispatch();

    const orgId = useSelector<RootState, string | null>((state) => state.organisation.currentOrgId);
    const organisations = useSelector<RootState, Organisation[]>((state) => state.organisation.organisations);

    useEffect(() => {
        const orgId = cookie.get(ORG_ID_COOKIE);
        const refreshIntervalJWT = setInterval(() => {
            const jwt = cookie.get(USER_TOKEN_COOKIE);
            if (jwt) {
                const info: { data: OrganisationInfo } = jwtDecode(jwt);
                dispatch(setCurrentOrgInfo(info.data));
                clearInterval(refreshIntervalJWT);
            }
        }, 333);

        if (!organisations || organisations.length === 0) {
            dispatch(getOrganisations());
        } else {
            dispatch(setOrganisations(organisations));
        }
        if (orgId) {
            dispatch(setCurrentOrgId(orgId));
        }
    }, [dispatch]);


    useEffect(() => {
        setInterval(() => {
            const jwt = cookie.get(USER_TOKEN_COOKIE);
            if (jwt) {
                const info: { data: OrganisationInfo, exp: number } = jwtDecode(jwt);
                dispatch(setCurrentOrgInfo(info.data));
                const remainSeconds = info.exp - (Date.now() / 1000);
                const refreshToken = cookie.get(USER_REFRESH_TOKEN_COOKIE);
                const currentUserTokenData:any = jwtDecode(cookie.get(USER_TOKEN_COOKIE) || '');
                const organisationId = cookie.get(ORG_ID_COOKIE) || '';
                if ((remainSeconds < 100 || !currentUserTokenData.data.org) && refreshToken) {
                    const formData = serializeObject({
                        refresh_token: refreshToken,
                        organisation_id: organisationId,
                        grant_type: GRANT_TYPE,
                        client_id: config.client_id,
                        redirect_uri: config.redirect_uri,
                        scope: SCOPE,
                    });
                    httpClient.post('/oauth/token', formData).then(({data}) => {
                        cookie.set(USER_TOKEN_COOKIE, data.access_token, {
                            domain: config.appDomain,
                            secure: true,
                            sameSite: 'strict'
                        });
                        cookie.set(USER_REFRESH_TOKEN_COOKIE, data.refresh_token, {
                            domain: config.appDomain,
                            secure: true,
                            sameSite: 'strict'
                        });
                    })
                }
            }

        }, 2000)
    }, []);

    useEffect(() => {
        if (orgId) {
            cookie.set(ORG_ID_COOKIE, orgId, {domain: config.appDomain});
        }
    }, [orgId]);

    return [organisations.length > 0, !!orgId];
};
