import { RootState } from 'redux/store';
import {
  getTableDataFilteredSorted,
  getTableDataFilteredSortedPaginated,
} from 'shared/components/Table/higherOrderRedux/selectors';
import { PaginationInfo, TableDataRow } from 'shared/components/Table/higherOrderRedux/types';
import { SortInfo, TableFilters } from 'shared/components/Table/types';

export const getData = (state: RootState): TableDataRow[] => {
  return state.organisation.employees as unknown as TableDataRow[];
}
export const getSort = (state: RootState): SortInfo => state.employeesTable.sort;
export const getFilters = (state: RootState): TableFilters => state.employeesTable.filters;
export const getPagination = (state: RootState): PaginationInfo => state.employeesTable.pagination;
export const getEmployeesTableDataWithPagination = getTableDataFilteredSortedPaginated({
  getData,
  getSort,
  getFilters,
  getPagination,
});

export const getEmployeesTableData = getTableDataFilteredSorted({
  getData,
  getSort,
  getFilters,
  getPagination,
});
