import { createSlice } from '@reduxjs/toolkit';
import { getUser, updateUser } from './thunks';
import { UserState } from './types';

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload: { user } }) => {
      state.user = user;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      if (state.user && state.user.information) {
        state.user.information = payload ? (payload as any).details : state.user.information;
      }
    });
  },
});

export default userSlice.reducer;
