import { createSlice } from '@reduxjs/toolkit';
import { getFilesList } from './thunks';
import { TermsPageSlice } from './types';

const initialState: TermsPageSlice = {
  files: [],
};

export const slice = createSlice({
  name: 'termsPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFilesList.fulfilled, (state, action) => {
      state.files = action.payload;
    });
  },
});

export default slice.reducer;
