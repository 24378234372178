import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Organisation from 'interfaces/organisation';
import { RootState } from 'redux/store';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import { getCurrentOrganisation } from 'redux/features/organisation';
import { RegistrationProject } from './components/RegistrationProject';
import { getAliases, getConfig } from './redux';

import styles from './Settings.module.scss';
import NavLinks from 'components/NavLinks/NavLinks';
import { setAccountPath } from 'redux/features/accountPath';

const SettingsComponent: FC = () => {
  const [isRegistrationSecltionOpen, setIsRegistrationSecltionOpen] = useState(true);
  const dispatch = useDispatch();
  const aliases = useSelector<RootState, string[]>((state) => state.settingsPage.aliases);
  const gcpList = useSelector<RootState, string[]>((state) => state.settingsPage.gcpList);
  const gtinWaiver = useSelector<RootState, boolean>((state) => state.settingsPage.gtinWaiver);
  const currentOrganisation = useSelector<RootState, Organisation | undefined>(getCurrentOrganisation);

  const toggleRegistrationSectionOpen = useCallback(
    () => setIsRegistrationSecltionOpen((isOpen) => !isOpen),
    [setIsRegistrationSecltionOpen]
  );

  const currentOrganisationName = currentOrganisation?.name || '';

  useEffect(() => {
    return () => {
      dispatch(setAccountPath({ path: '/', label: 'My Account' }))
    }
  }, [])

  useEffect(() => {
    dispatch(getAliases());
    dispatch(getConfig());
  }, []);

  return (
    <div className={styles.root}>
      <PageBlock>
        <div className={styles.content}>
          <NavLinks />
          <RegistrationProject
            gtinWaiver={gtinWaiver}
            gcpList={gcpList}
            aliases={aliases}
            organisationName={currentOrganisationName}
          />
        </div>
      </PageBlock>
    </div>
  );
};

export const Settings = memo(SettingsComponent);
