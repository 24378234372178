import React, { FC, memo } from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';
import { ReactComponent as BusinessInfoIcon } from 'static/icons/business-icon.svg';
import styles from '../../Company.module.scss';

interface Props {
  values: { [key: string]: any }
}

const BusinessInfoComponent: FC<Props> = ({ values }) => {
  const {legal_company_name, company_registration_number, business_unit, company_number, uk_vat, phone} = values;

  return (
    <>
      <div className={styles.mainTitle}>
        <BusinessInfoIcon className={styles.titleIcon} />
        <p className={styles.titleText}>Business Info</p>
      </div>

      <InputField fieldName="Legal Company Name" value={legal_company_name} disabled>
        <Input name="legal_company_name" disabled formikField />
      </InputField>

      <InputField fieldName="Company Registration Number" value={company_registration_number} disabled>
        <Input name="company_registration_number" disabled formikField />
      </InputField>

      <InputField fieldName="Business Unit" value={business_unit} disabled>
        <Input name="business_unit" disabled formikField />
      </InputField>

      <InputField fieldName="Company Number" value={company_number} disabled>
        <Input name="company_number" disabled formikField />
      </InputField>

      <InputField fieldName="UK VAT" value={uk_vat} disabled>
        <Input name="uk_vat" disabled formikField />
      </InputField>

      <InputField fieldName="Phone" value={phone} disabled>
        <Input required={true} disabled name="phone" formikField />
      </InputField>
    </>
  );
};

export const BusinessInfo = memo(BusinessInfoComponent);
