import config from 'config/env-config';
import { XelacoreModule } from 'enums/xelacoreModule';

import { ReactComponent as RegIcon } from 'static/icons/xelacoreModules/Fabacus_Module_Icons_Register.svg';
import { ReactComponent as PlmIcon } from 'static/icons/xelacoreModules/Fabacus_Module_Icons_PLM.svg';
import { ReactComponent as CamIcon } from 'static/icons/xelacoreModules/Fabacus_Module_Icons_Create.svg';
import { ReactComponent as ReachIcon } from 'static/icons/xelacoreModules/Fabacus_Module_Icons_Reach.svg';
import { XelacoreModuleDefinition } from 'interfaces/xelacoreModuleDefinition';

export const xelacoreModuleDefinitions = new Map<XelacoreModule, XelacoreModuleDefinition>([
  [
    XelacoreModule.reg,
    {
      name: 'Registration',
      icon: <RegIcon></RegIcon>,
      link: config.services.reg,
    },
  ],
  [
    XelacoreModule.plm,
    {
      name: 'PLM',
      icon: <PlmIcon></PlmIcon>,
      link: config.services.plm,
    },
  ],
  [
    XelacoreModule.reach,
    {
      name: 'Reach',
      icon: <ReachIcon></ReachIcon>,
      link: config.services.reach,
    },
  ],
  [
    XelacoreModule.cam,
    {
      name: 'Concept Approval',
      icon: <CamIcon></CamIcon>,
      link: config.services.cam,
    },
  ],
]);
