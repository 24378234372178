import React from 'react';
import Select, { OptionsType } from 'react-select';
import styles from './Dropdown.module.scss';
import classNames from 'classnames';
import { Option } from './types';

interface Props {
  error?: string | null;
  isMulti?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  name?: string;
  value: string | number;
  options: Option[];
  className?: string;
  onChange: (value: string | number) => void;
}

export default function Dropdown({
  name,
  value,
  options,
  className,
  error,
  onChange,
  isMulti = false,
  isDisabled = false,
  isSearchable = false,
}: Props): React.ReactElement {
  return (
    <div className={styles.root}>
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: '#176bfb',
            color: '#000',
            primary25: '#e8f0ff',
            primary50: '#B7D1FE',
            primary75: '#B7D1FE',
            primary: '#B7D1FE',
          },
        })}
        options={options}
        name={name}
        isMulti={isMulti}
        isDisabled={isDisabled}
        className={classNames(styles.select, className, { [styles.hasError]: !!error })}
        value={options.find((option) => option.value === value)}
        onChange={(option: Option | OptionsType<Option> | null) => onChange((option as Option).value)}
        isSearchable={isSearchable}
      ></Select>
      {!!error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}
